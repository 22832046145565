import moment from 'moment';

import { CONTRACT_FIXATION_GRANULARITY } from '../constants';

export const getFormattedLabelSelectFixation = (currentDate, granularity) => {
  switch (granularity) {
    case CONTRACT_FIXATION_GRANULARITY.MONTH:
      return currentDate.format('MMMM');
    case CONTRACT_FIXATION_GRANULARITY.QUARTER:
      return `${currentDate.format('MMM')} - ${moment(currentDate).add(2, 'month').format('MMM')}`;
    case CONTRACT_FIXATION_GRANULARITY.YEAR:
      return `${currentDate.format('MMM YYYY')} - ${moment(currentDate).add(11, 'months').format('MMM YYYY')}`;
    case CONTRACT_FIXATION_GRANULARITY.GASYEAR:
      return `${currentDate.format('MMM YYYY')} - ${moment(currentDate).add(11, 'months').format('MMM YYYY')}`;
    case CONTRACT_FIXATION_GRANULARITY.SEMESTER:
      return `${moment(currentDate).format('MMM YYYY')} - ${moment(currentDate).add(5, 'month').format('MMM YYYY')}`;
    case CONTRACT_FIXATION_GRANULARITY.SUMMER_WINTER:
      return `${moment(currentDate).format('MMM YYYY')} - ${moment(currentDate).add(5, 'month').format('MMM YYYY')}`;
    default:
      throw new Error(`This granularity is not handled: ${granularity}`);
  }
};

/**
 * getContractYearTabs
 *
 * @param contractStart moment
 * @param contractEnd moment
 * @returns {[]}
 */
export const getContractYearTabs = (contractStart, contractEnd) => {
  const controls = [];
  const startMonth = moment(contractStart).startOf('month');
  const endMonth = moment(contractEnd).startOf('month');

  for (let year = startMonth.year(); year <= endMonth.year(); year += 1) {
    controls.push(year);
  }

  return controls;
};

export const getContractFixationsControls = (
  contractStart,
  contractEnd,
  year,
  fixationGranularity,
  energy,
  contractFixation,
) => {
  const controls = [];
  let startDate = moment(contractStart).startOf('month');

  const yearStart = moment(`${year}-01-01`);
  let endDate = moment(contractEnd).startOf('month');

  let yearLastMonth = moment(`${year}-12-01`);
  if (fixationGranularity === CONTRACT_FIXATION_GRANULARITY.YEAR) {
    // Set startDate to contractStart + year index
    if (moment(contractStart).format('M') === '1') {
      const yearOffset = year - startDate.year();

      startDate = moment(contractStart).add(yearOffset, 'year');
    }
  } else if (fixationGranularity === CONTRACT_FIXATION_GRANULARITY.GASYEAR) {
    // Set startDate to contractStart + year index
    startDate = moment(contractStart);
    yearLastMonth = moment(yearLastMonth).add(12, 'month');
  } else if (
    [CONTRACT_FIXATION_GRANULARITY.MONTH, CONTRACT_FIXATION_GRANULARITY.QUARTER].includes(fixationGranularity)
  ) {
    if (startDate.isBefore(yearStart)) startDate = yearStart;
  } else if (
    fixationGranularity === CONTRACT_FIXATION_GRANULARITY.SEMESTER ||
    fixationGranularity === CONTRACT_FIXATION_GRANULARITY.SUMMER_WINTER
  ) {
    while (startDate.isBefore(yearStart)) {
      startDate.add(6, 'month');
    }
  }

  if (endDate.isAfter(yearLastMonth)) endDate = yearLastMonth;

  let previousDate = null;
  const currentDate = moment(startDate);
  while (currentDate.isSameOrBefore(endDate)) {
    let createControl = false;
    let label = '';
    let months = 0;
    switch (fixationGranularity) {
      case CONTRACT_FIXATION_GRANULARITY.MONTH:
        createControl = true;
        label = `${getFormattedLabelSelectFixation(currentDate, fixationGranularity)} - ${year}`;
        months = 1;
        break;
      case CONTRACT_FIXATION_GRANULARITY.QUARTER:
        // eslint-disable-next-line no-case-declarations
        const currentMonthQuarter = currentDate.quarter();
        // eslint-disable-next-line no-case-declarations
        const lastMonthQuarter = previousDate ? previousDate.quarter() : -1;
        if (currentMonthQuarter !== lastMonthQuarter) {
          createControl = true;
          label = `${getFormattedLabelSelectFixation(currentDate, fixationGranularity)} ${year}`;
          months = 3;
        }
        break;
      case CONTRACT_FIXATION_GRANULARITY.YEAR:
        // eslint-disable-next-line no-case-declarations
        const currentMonthYear = currentDate.year();
        // eslint-disable-next-line no-case-declarations
        const lastMonthYear = previousDate ? currentDate.year() : -1;
        // console.log("currentDate", moment(currentDate).format('DD-MM-YYYY'));
        if (
          currentMonthYear !== lastMonthYear &&
          moment(currentDate).format('M') === '1' &&
          moment(currentDate).isSameOrBefore(endDate) &&
          moment(currentDate).add(11, 'months').isSameOrBefore(endDate, 'day')
        ) {
          createControl = true;
          label = getFormattedLabelSelectFixation(currentDate, fixationGranularity);
          months = 12;
        }
        break;
      case CONTRACT_FIXATION_GRANULARITY.GASYEAR:
        // eslint-disable-next-line no-case-declarations
        const currentMonthGasYear = currentDate.year();
        // eslint-disable-next-line no-case-declarations
        const lastMonthGasYear = previousDate ? currentDate.year() : -1;
        if (
          currentMonthGasYear !== lastMonthGasYear &&
          moment(currentDate).format('M') === '10' &&
          moment(currentDate).add(11, 'months').format('DD-MM-YYYY') <= moment(contractEnd).format('DD-MM-YYYY') &&
          moment(currentDate).add(11, 'months').format('YYYY') <= moment(contractEnd).format('YYYY')
        ) {
          createControl = true;
          label = getFormattedLabelSelectFixation(currentDate, fixationGranularity);
          months = 12;
        }
        break;
      // } else break;
      case CONTRACT_FIXATION_GRANULARITY.SEMESTER:
        // eslint-disable-next-line no-case-declarations
        const currentMonthSemester = currentDate.month();
        // eslint-disable-next-line no-case-declarations
        const lastMonthSemester = previousDate ? previousDate.month() : currentMonthSemester;
        if (
          Math.abs(currentMonthSemester - lastMonthSemester) === 6 ||
          Math.abs(currentMonthSemester - lastMonthSemester) === 0
        ) {
          createControl = true;
          label = getFormattedLabelSelectFixation(currentDate, fixationGranularity);
          months = 6;
        }
        break;
      case CONTRACT_FIXATION_GRANULARITY.SUMMER_WINTER:
        // eslint-disable-next-line no-case-declarations
        const currentMonthSummer = currentDate.month();
        // eslint-disable-next-line no-case-declarations
        const lastMonthSummer = previousDate ? previousDate.month() : currentMonthSummer;
        if (
          (Math.abs(currentMonthSummer - lastMonthSummer) === 6 ||
            Math.abs(currentMonthSummer - lastMonthSummer) === 0) &&
          (moment(currentDate).format('M') === '4' || moment(currentDate).format('M') === '10') &&
          moment(currentDate).add(5, 'months').format('YYYY') <= moment(contractEnd).format('YYYY') &&
          moment(currentDate).add(5, 'months').format('DD-MM-YYYY') <= moment(contractEnd).format('DD-MM-YYYY')
        ) {
          createControl = true;
          label = getFormattedLabelSelectFixation(currentDate, fixationGranularity);
          months = 6;
        }
        break;
      // } else break;
      default:
        return;
    }
    if (createControl) {
      controls.push({
        date: moment(currentDate),
        months,
        label,
        contractFixation,
      });
      previousDate = moment(currentDate);
    }
    currentDate.add(1, 'month');
  }
  // eslint-disable-next-line consistent-return
  return controls;
};

export const getDefaultProductsControls = (
  gran,
  periodMonth,
  periodQuarter,
  // periodSemester,
  periodSummerWinter,
  periodYear,
  periodGasYear,
) => {
  const controls = [];
  const month = periodMonth;
  const quarter = periodQuarter;
  // const semester = periodSemester;
  const year = periodYear;
  const summer_winter = periodSummerWinter;
  const gasyear = periodGasYear;

  switch (gran) {
    case CONTRACT_FIXATION_GRANULARITY.MONTH:
      for (let i = 0; i < month.length; i += 1) {
        controls.push(month[i]);
      }
      break;
    case CONTRACT_FIXATION_GRANULARITY.QUARTER:
      for (let i = 0; i < quarter.length; i += 1) {
        controls.push(quarter[i]);
      }
      break;
    case CONTRACT_FIXATION_GRANULARITY.SUMMER_WINTER:
      for (let i = 0; i < summer_winter.length; i += 1) {
        controls.push(summer_winter[i]);
      }
      break;
    case CONTRACT_FIXATION_GRANULARITY.YEAR:
      for (let i = 0; i < year.length; i += 1) {
        controls.push(year[i]);
      }
      break;
    case CONTRACT_FIXATION_GRANULARITY.GASYEAR:
      for (let i = 0; i < gasyear.length; i += 1) {
        controls.push(gasyear[i]);
      }
      break;
    default:
      return true;
  }

  // eslint-disable-next-line consistent-return
  return controls;
};

export const getContractFixationsPeriods = (contractStart, contractEnd, year) => {
  const controls = [];
  let startDate = moment(contractStart).startOf('month');

  const yearStart = moment(`${year}-01-01`);
  let endDate = moment(contractEnd).startOf('month');

  const yearLastMonth = moment(`${year}-12-01`);

  if (startDate.isBefore(yearStart)) startDate = yearStart;
  if (endDate.isAfter(yearLastMonth)) endDate = yearLastMonth;

  const currentDate = moment(startDate);
  while (currentDate.isSameOrBefore(endDate)) {
    controls.push({
      date: moment(currentDate),
      period: `${currentDate.format('YYYY-MM-DD')}`,
      label: `${moment(currentDate).format('MMMM YYYY')}`,
    });
    currentDate.add(1, 'month');
  }
  // eslint-disable-next-line consistent-return
  return controls;
};

export const getFixationInitialValue = (minimumValue, startIndex = 0, maxValue = 100) => {
  const arr = [];
  // eslint-disable-next-line
  while (true) {
    const final = startIndex * parseFloat(minimumValue);
    if (final > maxValue) {
      return arr;
    }
    arr.push(final);
    startIndex += 1;
  }
};

export const getCorrectLabelFixation = (number) => {
  const parsedNumber = parseFloat(number.replace(',', '.'));

  return `${parsedNumber === 99 ? 100 : parsedNumber}%`;
};

export const getCorrectValueFixation = (number) => {
  if (number === 1) return 0;
  if (number === 34) return 33;
  if (number === 67) return 66;
  if (number === 99) return 100;

  return number;
};

export const getDisableDateForFixation = (periodStart, isAdmin = false) => {
  return (date) => {
    if (isAdmin) {
      return false;
    }

    const periodMoment = moment(periodStart, 'YYYY-MM-DD');

    return moment(date).isSameOrAfter(periodMoment);
  };
};

export const getDisableDateForBlockFixation = (isAdmin = false) => {
  return (date) => {
    if (isAdmin) {
      return false;
    }

    const today = moment();
    const periodMoment = moment(today, 'YYYY-MM-DD');

    return moment(date).isAfter(periodMoment);
  };
};

export const getDisableDateForAppointment = (isAdmin = false) => {
  return (date) => {
    if (isAdmin) {
      return false;
    }

    const today = moment();
    const periodMoment = moment(today, 'YYYY-MM-DD');

    return moment(date).isSameOrBefore(periodMoment);
  };
};

export const getDisableBeforeDate = (isAdmin = false) => {
  return (date) => {
    if (isAdmin) {
      return false;
    }

    const today = moment();
    const periodMoment = moment(today, 'YYYY-MM-DD');

    return moment(date).isSameOrBefore(periodMoment);
  };
};

export const getTodayDate = () => {
  const todayDate = moment();
  const periodMoment = moment(todayDate, 'YYYY-MM-DD');

  return periodMoment;
};

export const getDelivery = () => {
  const todayDate = moment();
  // const periodSart = moment(todayDate);
  const periodEnd = moment().add(11, 'month');

  return [moment(todayDate, 'YYYY-MM-DD'), moment(periodEnd, 'YYYY-MM-DD')];
};

export const getDisablePeriodDateForBlockFixation = (periodStart, periodEnd, isAdmin = false) => {
  return (date) => {
    if (isAdmin) {
      return false;
    }

    const periodSMoment = moment(periodStart, 'YYYY-MM-DD');
    const periodEMoment = moment(periodEnd, 'YYYY-MM-DD').add(1, 'month');

    return moment(date).isSameOrBefore(periodSMoment) || moment(date).isSameOrAfter(periodEMoment);
  };
};

export const getDisablePeriodDateForChart = (periodStart, periodEnd, isAdmin = false) => {
  return (date) => {
    if (isAdmin) {
      return false;
    }

    const periodSMoment = moment(periodStart, 'YYYY-MM-DD');
    const periodEMoment = moment(periodEnd, 'YYYY-MM-DD');

    return moment(date).isSameOrBefore(periodSMoment) || moment(date).isSameOrAfter(periodEMoment);
  };
};

export const getDisablePeriodDateForBlockFixationUpdate = (start, end, isAdmin = false) => {
  return (date) => {
    if (isAdmin) {
      return false;
    }

    const periodSMoment = moment(start, 'YYYY-MM-DD');
    const periodEMoment = moment(end, 'YYYY-MM-DD');

    return moment(date).isSameOrBefore(periodSMoment) || moment(date).isSameOrAfter(periodEMoment);
  };
};

export const getDefaultValueForDatePicker = (periodStart) => {
  const today = moment();
  const periodMoment = moment(periodStart, 'YYYY-MM-DD');

  const isFuturePeriod = today.diff(periodMoment) < 0;

  return isFuturePeriod ? today : periodMoment.add(-1, 'month');
};
